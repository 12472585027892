import React, { Fragment, useState, useRef } from "react"
import styled from "styled-components"
import TransitionLink from "gatsby-plugin-transition-link"
import { Link } from "gatsby"
import Logo from "src/img/svg/logo.svg"

const StyledLogo = styled(Logo)`
  transform: scale(0.8);
`

const NavBar = styled.div`
  @media screen and (max-width: 1140px) {
    overflow: hidden;
  }
`

const NavBarUl = styled.ul`
  @media screen and (max-width: 1140px) {
    padding-left: 2em;
  }
`

export default function Container({ children, className }) {
  const navbarNav = useRef()
  const [open, setOpen] = useState(false)
  const handlerButton = () => {
    setOpen(!open)
  }

  return (
    <Fragment>
      <nav className="navbar navbar-expand-xl navbar-dark bg-dark p-0">
        <span className="navbar-brand mb-0 mr-0 h1">
          <Link to="/">
            <StyledLogo />
          </Link>
        </span>
        <button
          className="navbar-toggler mr-4"
          type="button"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={handlerButton}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <NavBar
          className="navbar-collapse"
          id="navbarNav"
          ref={navbarNav}
          style={{ height: open ? "100%" : "0" }}
        >
          <NavBarUl className="navbar-nav">
            <li className="nav-item">
              <TransitionLink
                activeClassName="active"
                className="nav-link"
                to="/pentest"
              >
                Тестирование на проникновение
              </TransitionLink>
            </li>
            <li className="nav-item">
              <TransitionLink
                activeClassName="active"
                className="nav-link"
                to="/develop"
              >
                Разработка
              </TransitionLink>
            </li>
            <li className="nav-item">
              <TransitionLink
                activeClassName="active"
                className="nav-link"
                to="/contact"
              >
                Контакты
              </TransitionLink>
            </li>
          </NavBarUl>
        </NavBar>
      </nav>
      {children}
    </Fragment>
  )
}
